html {
  width: 100%;
  height: calc(100vh - 1px);
}

body {
  position: fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', SegoeUI,
    'Microsoft JhengHei', 微軟正黑體, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

input[type='date'].unfilled:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
